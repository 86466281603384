@import "../../scss/base.scss";

.loader-block {
  position: fixed;
  z-index: 150;
  background: rgba(0, 0, 0, 0.4);
  height: 102.5%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  &.loading {
    .loader {
      border: 16px solid $white-ter;
      border-radius: 50%;
      border-top: 16px solid $golden;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
      margin-left: auto;
      margin-right: auto;
      margin-top: 22.23em;
    }
    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  &.blank-page-loader {
    background-color: black;
    .loader {
      display: flex;
      justify-content: center;
      color: $white;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
