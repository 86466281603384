// colors
$black: #000000;
$black-secondary: #2d2d2d;
$black-tertiary: #222222;
$golden: #c5a45e;
$golden-secondary: #daa520;
$golden-tertiary: #a68030;
$white: #ffffff;
$white-secondary: #e5e5e5;
$white-ter: #f3f3f3;
$red: red;
$footer-text-color: #c4c4c4;
$footer-sec-color: #717070;
$grey-primary: #767676;
$grey-sec: #7d7c7c;
$influencer-expert: #bbb9b9;
$footer-top: #383737;
$checbox-color: #c4c4c4;
$signout-popup-bg-color: #161616;
$signout-popup-box-shadow: rgba(0, 0, 0, 0.25);
$search-results-divider: #3e3e3e;
$modal-bg-color: rgba(0, 0, 0, 0.5);
$grey-bg-color: #f0f0f0;
$modal-grey-bg-color: #f7f7f7;
$modal-border: #eee;
$search-title: #3c3c3c;
$see-more-search: #bababa;
$greyish-black: #606060;
$blackish-grey: #403f42;

//font

$font-secondary: "PT Serif", serif;

// font-sizes
$error-font: 11px;

// height
$header-height: 100px;

// breakpoints
$mobile-s: 321px;
$mobile-m: 376px;
$mobile-l: 426px;
$mobile: 540px;
$tablet: 769px;
$desktop: 960px;
$widescreen: 1200px;
$widescreen-enabled: true;
